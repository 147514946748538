import _d3Array from "d3-array";
var exports = {};

// https://d3js.org/d3-time/ v2.1.1 Copyright 2021 Mike Bostock
(function (global, factory) {
  factory(exports, _d3Array);
})(exports, function (exports, d3Array) {
  'use strict';

  var t0 = new Date(),
      t1 = new Date();

  function newInterval(floori, offseti, count, field) {
    function interval(date) {
      return floori(date = arguments.length === 0 ? new Date() : new Date(+date)), date;
    }

    interval.floor = function (date) {
      return floori(date = new Date(+date)), date;
    };

    interval.ceil = function (date) {
      return floori(date = new Date(date - 1)), offseti(date, 1), floori(date), date;
    };

    interval.round = function (date) {
      var d0 = interval(date),
          d1 = interval.ceil(date);
      return date - d0 < d1 - date ? d0 : d1;
    };

    interval.offset = function (date, step) {
      return offseti(date = new Date(+date), step == null ? 1 : Math.floor(step)), date;
    };

    interval.range = function (start, stop, step) {
      var range = [],
          previous;
      start = interval.ceil(start);
      step = step == null ? 1 : Math.floor(step);
      if (!(start < stop) || !(step > 0)) return range; // also handles Invalid Date

      do range.push(previous = new Date(+start)), offseti(start, step), floori(start); while (previous < start && start < stop);

      return range;
    };

    interval.filter = function (test) {
      return newInterval(function (date) {
        if (date >= date) while (floori(date), !test(date)) date.setTime(date - 1);
      }, function (date, step) {
        if (date >= date) {
          if (step < 0) while (++step <= 0) {
            while (offseti(date, -1), !test(date)) {} // eslint-disable-line no-empty

          } else while (--step >= 0) {
            while (offseti(date, +1), !test(date)) {} // eslint-disable-line no-empty

          }
        }
      });
    };

    if (count) {
      interval.count = function (start, end) {
        t0.setTime(+start), t1.setTime(+end);
        floori(t0), floori(t1);
        return Math.floor(count(t0, t1));
      };

      interval.every = function (step) {
        step = Math.floor(step);
        return !isFinite(step) || !(step > 0) ? null : !(step > 1) ? interval : interval.filter(field ? function (d) {
          return field(d) % step === 0;
        } : function (d) {
          return interval.count(0, d) % step === 0;
        });
      };
    }

    return interval;
  }

  var millisecond = newInterval(function () {// noop
  }, function (date, step) {
    date.setTime(+date + step);
  }, function (start, end) {
    return end - start;
  }); // An optimized implementation for this simple case.

  millisecond.every = function (k) {
    k = Math.floor(k);
    if (!isFinite(k) || !(k > 0)) return null;
    if (!(k > 1)) return millisecond;
    return newInterval(function (date) {
      date.setTime(Math.floor(date / k) * k);
    }, function (date, step) {
      date.setTime(+date + step * k);
    }, function (start, end) {
      return (end - start) / k;
    });
  };

  var milliseconds = millisecond.range;
  const durationSecond = 1000;
  const durationMinute = durationSecond * 60;
  const durationHour = durationMinute * 60;
  const durationDay = durationHour * 24;
  const durationWeek = durationDay * 7;
  const durationMonth = durationDay * 30;
  const durationYear = durationDay * 365;
  var second = newInterval(function (date) {
    date.setTime(date - date.getMilliseconds());
  }, function (date, step) {
    date.setTime(+date + step * durationSecond);
  }, function (start, end) {
    return (end - start) / durationSecond;
  }, function (date) {
    return date.getUTCSeconds();
  });
  var seconds = second.range;
  var minute = newInterval(function (date) {
    date.setTime(date - date.getMilliseconds() - date.getSeconds() * durationSecond);
  }, function (date, step) {
    date.setTime(+date + step * durationMinute);
  }, function (start, end) {
    return (end - start) / durationMinute;
  }, function (date) {
    return date.getMinutes();
  });
  var minutes = minute.range;
  var hour = newInterval(function (date) {
    date.setTime(date - date.getMilliseconds() - date.getSeconds() * durationSecond - date.getMinutes() * durationMinute);
  }, function (date, step) {
    date.setTime(+date + step * durationHour);
  }, function (start, end) {
    return (end - start) / durationHour;
  }, function (date) {
    return date.getHours();
  });
  var hours = hour.range;
  var day = newInterval(date => date.setHours(0, 0, 0, 0), (date, step) => date.setDate(date.getDate() + step), (start, end) => (end - start - (end.getTimezoneOffset() - start.getTimezoneOffset()) * durationMinute) / durationDay, date => date.getDate() - 1);
  var days = day.range;

  function weekday(i) {
    return newInterval(function (date) {
      date.setDate(date.getDate() - (date.getDay() + 7 - i) % 7);
      date.setHours(0, 0, 0, 0);
    }, function (date, step) {
      date.setDate(date.getDate() + step * 7);
    }, function (start, end) {
      return (end - start - (end.getTimezoneOffset() - start.getTimezoneOffset()) * durationMinute) / durationWeek;
    });
  }

  var sunday = weekday(0);
  var monday = weekday(1);
  var tuesday = weekday(2);
  var wednesday = weekday(3);
  var thursday = weekday(4);
  var friday = weekday(5);
  var saturday = weekday(6);
  var sundays = sunday.range;
  var mondays = monday.range;
  var tuesdays = tuesday.range;
  var wednesdays = wednesday.range;
  var thursdays = thursday.range;
  var fridays = friday.range;
  var saturdays = saturday.range;
  var month = newInterval(function (date) {
    date.setDate(1);
    date.setHours(0, 0, 0, 0);
  }, function (date, step) {
    date.setMonth(date.getMonth() + step);
  }, function (start, end) {
    return end.getMonth() - start.getMonth() + (end.getFullYear() - start.getFullYear()) * 12;
  }, function (date) {
    return date.getMonth();
  });
  var months = month.range;
  var year = newInterval(function (date) {
    date.setMonth(0, 1);
    date.setHours(0, 0, 0, 0);
  }, function (date, step) {
    date.setFullYear(date.getFullYear() + step);
  }, function (start, end) {
    return end.getFullYear() - start.getFullYear();
  }, function (date) {
    return date.getFullYear();
  }); // An optimized implementation for this simple case.

  year.every = function (k) {
    return !isFinite(k = Math.floor(k)) || !(k > 0) ? null : newInterval(function (date) {
      date.setFullYear(Math.floor(date.getFullYear() / k) * k);
      date.setMonth(0, 1);
      date.setHours(0, 0, 0, 0);
    }, function (date, step) {
      date.setFullYear(date.getFullYear() + step * k);
    });
  };

  var years = year.range;
  var utcMinute = newInterval(function (date) {
    date.setUTCSeconds(0, 0);
  }, function (date, step) {
    date.setTime(+date + step * durationMinute);
  }, function (start, end) {
    return (end - start) / durationMinute;
  }, function (date) {
    return date.getUTCMinutes();
  });
  var utcMinutes = utcMinute.range;
  var utcHour = newInterval(function (date) {
    date.setUTCMinutes(0, 0, 0);
  }, function (date, step) {
    date.setTime(+date + step * durationHour);
  }, function (start, end) {
    return (end - start) / durationHour;
  }, function (date) {
    return date.getUTCHours();
  });
  var utcHours = utcHour.range;
  var utcDay = newInterval(function (date) {
    date.setUTCHours(0, 0, 0, 0);
  }, function (date, step) {
    date.setUTCDate(date.getUTCDate() + step);
  }, function (start, end) {
    return (end - start) / durationDay;
  }, function (date) {
    return date.getUTCDate() - 1;
  });
  var utcDays = utcDay.range;

  function utcWeekday(i) {
    return newInterval(function (date) {
      date.setUTCDate(date.getUTCDate() - (date.getUTCDay() + 7 - i) % 7);
      date.setUTCHours(0, 0, 0, 0);
    }, function (date, step) {
      date.setUTCDate(date.getUTCDate() + step * 7);
    }, function (start, end) {
      return (end - start) / durationWeek;
    });
  }

  var utcSunday = utcWeekday(0);
  var utcMonday = utcWeekday(1);
  var utcTuesday = utcWeekday(2);
  var utcWednesday = utcWeekday(3);
  var utcThursday = utcWeekday(4);
  var utcFriday = utcWeekday(5);
  var utcSaturday = utcWeekday(6);
  var utcSundays = utcSunday.range;
  var utcMondays = utcMonday.range;
  var utcTuesdays = utcTuesday.range;
  var utcWednesdays = utcWednesday.range;
  var utcThursdays = utcThursday.range;
  var utcFridays = utcFriday.range;
  var utcSaturdays = utcSaturday.range;
  var utcMonth = newInterval(function (date) {
    date.setUTCDate(1);
    date.setUTCHours(0, 0, 0, 0);
  }, function (date, step) {
    date.setUTCMonth(date.getUTCMonth() + step);
  }, function (start, end) {
    return end.getUTCMonth() - start.getUTCMonth() + (end.getUTCFullYear() - start.getUTCFullYear()) * 12;
  }, function (date) {
    return date.getUTCMonth();
  });
  var utcMonths = utcMonth.range;
  var utcYear = newInterval(function (date) {
    date.setUTCMonth(0, 1);
    date.setUTCHours(0, 0, 0, 0);
  }, function (date, step) {
    date.setUTCFullYear(date.getUTCFullYear() + step);
  }, function (start, end) {
    return end.getUTCFullYear() - start.getUTCFullYear();
  }, function (date) {
    return date.getUTCFullYear();
  }); // An optimized implementation for this simple case.

  utcYear.every = function (k) {
    return !isFinite(k = Math.floor(k)) || !(k > 0) ? null : newInterval(function (date) {
      date.setUTCFullYear(Math.floor(date.getUTCFullYear() / k) * k);
      date.setUTCMonth(0, 1);
      date.setUTCHours(0, 0, 0, 0);
    }, function (date, step) {
      date.setUTCFullYear(date.getUTCFullYear() + step * k);
    });
  };

  var utcYears = utcYear.range;

  function ticker(year, month, week, day, hour, minute) {
    const tickIntervals = [[second, 1, durationSecond], [second, 5, 5 * durationSecond], [second, 15, 15 * durationSecond], [second, 30, 30 * durationSecond], [minute, 1, durationMinute], [minute, 5, 5 * durationMinute], [minute, 15, 15 * durationMinute], [minute, 30, 30 * durationMinute], [hour, 1, durationHour], [hour, 3, 3 * durationHour], [hour, 6, 6 * durationHour], [hour, 12, 12 * durationHour], [day, 1, durationDay], [day, 2, 2 * durationDay], [week, 1, durationWeek], [month, 1, durationMonth], [month, 3, 3 * durationMonth], [year, 1, durationYear]];

    function ticks(start, stop, count) {
      const reverse = stop < start;
      if (reverse) [start, stop] = [stop, start];
      const interval = count && typeof count.range === "function" ? count : tickInterval(start, stop, count);
      const ticks = interval ? interval.range(start, +stop + 1) : []; // inclusive stop

      return reverse ? ticks.reverse() : ticks;
    }

    function tickInterval(start, stop, count) {
      const target = Math.abs(stop - start) / count;
      const i = d3Array.bisector(([,, step]) => step).right(tickIntervals, target);
      if (i === tickIntervals.length) return year.every(d3Array.tickStep(start / durationYear, stop / durationYear, count));
      if (i === 0) return millisecond.every(Math.max(d3Array.tickStep(start, stop, count), 1));
      const [t, step] = tickIntervals[target / tickIntervals[i - 1][2] < tickIntervals[i][2] / target ? i - 1 : i];
      return t.every(step);
    }

    return [ticks, tickInterval];
  }

  const [utcTicks, utcTickInterval] = ticker(utcYear, utcMonth, utcSunday, utcDay, utcHour, utcMinute);
  const [timeTicks, timeTickInterval] = ticker(year, month, sunday, day, hour, minute);
  exports.timeDay = day;
  exports.timeDays = days;
  exports.timeFriday = friday;
  exports.timeFridays = fridays;
  exports.timeHour = hour;
  exports.timeHours = hours;
  exports.timeInterval = newInterval;
  exports.timeMillisecond = millisecond;
  exports.timeMilliseconds = milliseconds;
  exports.timeMinute = minute;
  exports.timeMinutes = minutes;
  exports.timeMonday = monday;
  exports.timeMondays = mondays;
  exports.timeMonth = month;
  exports.timeMonths = months;
  exports.timeSaturday = saturday;
  exports.timeSaturdays = saturdays;
  exports.timeSecond = second;
  exports.timeSeconds = seconds;
  exports.timeSunday = sunday;
  exports.timeSundays = sundays;
  exports.timeThursday = thursday;
  exports.timeThursdays = thursdays;
  exports.timeTickInterval = timeTickInterval;
  exports.timeTicks = timeTicks;
  exports.timeTuesday = tuesday;
  exports.timeTuesdays = tuesdays;
  exports.timeWednesday = wednesday;
  exports.timeWednesdays = wednesdays;
  exports.timeWeek = sunday;
  exports.timeWeeks = sundays;
  exports.timeYear = year;
  exports.timeYears = years;
  exports.utcDay = utcDay;
  exports.utcDays = utcDays;
  exports.utcFriday = utcFriday;
  exports.utcFridays = utcFridays;
  exports.utcHour = utcHour;
  exports.utcHours = utcHours;
  exports.utcMillisecond = millisecond;
  exports.utcMilliseconds = milliseconds;
  exports.utcMinute = utcMinute;
  exports.utcMinutes = utcMinutes;
  exports.utcMonday = utcMonday;
  exports.utcMondays = utcMondays;
  exports.utcMonth = utcMonth;
  exports.utcMonths = utcMonths;
  exports.utcSaturday = utcSaturday;
  exports.utcSaturdays = utcSaturdays;
  exports.utcSecond = second;
  exports.utcSeconds = seconds;
  exports.utcSunday = utcSunday;
  exports.utcSundays = utcSundays;
  exports.utcThursday = utcThursday;
  exports.utcThursdays = utcThursdays;
  exports.utcTickInterval = utcTickInterval;
  exports.utcTicks = utcTicks;
  exports.utcTuesday = utcTuesday;
  exports.utcTuesdays = utcTuesdays;
  exports.utcWednesday = utcWednesday;
  exports.utcWednesdays = utcWednesdays;
  exports.utcWeek = utcSunday;
  exports.utcWeeks = utcSundays;
  exports.utcYear = utcYear;
  exports.utcYears = utcYears;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
});

export default exports;
export const timeDay = exports.timeDay,
      timeDays = exports.timeDays,
      timeFriday = exports.timeFriday,
      timeFridays = exports.timeFridays,
      timeHour = exports.timeHour,
      timeHours = exports.timeHours,
      timeInterval = exports.timeInterval,
      timeMillisecond = exports.timeMillisecond,
      timeMilliseconds = exports.timeMilliseconds,
      timeMinute = exports.timeMinute,
      timeMinutes = exports.timeMinutes,
      timeMonday = exports.timeMonday,
      timeMondays = exports.timeMondays,
      timeMonth = exports.timeMonth,
      timeMonths = exports.timeMonths,
      timeSaturday = exports.timeSaturday,
      timeSaturdays = exports.timeSaturdays,
      timeSecond = exports.timeSecond,
      timeSeconds = exports.timeSeconds,
      timeSunday = exports.timeSunday,
      timeSundays = exports.timeSundays,
      timeThursday = exports.timeThursday,
      timeThursdays = exports.timeThursdays,
      timeTickInterval = exports.timeTickInterval,
      timeTicks = exports.timeTicks,
      timeTuesday = exports.timeTuesday,
      timeTuesdays = exports.timeTuesdays,
      timeWednesday = exports.timeWednesday,
      timeWednesdays = exports.timeWednesdays,
      timeWeek = exports.timeWeek,
      timeWeeks = exports.timeWeeks,
      timeYear = exports.timeYear,
      timeYears = exports.timeYears,
      utcDay = exports.utcDay,
      utcDays = exports.utcDays,
      utcFriday = exports.utcFriday,
      utcFridays = exports.utcFridays,
      utcHour = exports.utcHour,
      utcHours = exports.utcHours,
      utcMillisecond = exports.utcMillisecond,
      utcMilliseconds = exports.utcMilliseconds,
      utcMinute = exports.utcMinute,
      utcMinutes = exports.utcMinutes,
      utcMonday = exports.utcMonday,
      utcMondays = exports.utcMondays,
      utcMonth = exports.utcMonth,
      utcMonths = exports.utcMonths,
      utcSaturday = exports.utcSaturday,
      utcSaturdays = exports.utcSaturdays,
      utcSecond = exports.utcSecond,
      utcSeconds = exports.utcSeconds,
      utcSunday = exports.utcSunday,
      utcSundays = exports.utcSundays,
      utcThursday = exports.utcThursday,
      utcThursdays = exports.utcThursdays,
      utcTickInterval = exports.utcTickInterval,
      utcTicks = exports.utcTicks,
      utcTuesday = exports.utcTuesday,
      utcTuesdays = exports.utcTuesdays,
      utcWednesday = exports.utcWednesday,
      utcWednesdays = exports.utcWednesdays,
      utcWeek = exports.utcWeek,
      utcWeeks = exports.utcWeeks,
      utcYear = exports.utcYear,
      utcYears = exports.utcYears,
      __esModule = exports.__esModule;